import { Box, HStack, Text, Tooltip } from "@chakra-ui/react";
import { Advantage } from "@src/types/strapi";
import Image from "next/image";

type ProductAdvantageProps = Advantage;

export const ProductAdvantage = ({
  icon,
  title,
  description,
}: ProductAdvantageProps) => {
  return (
    <HStack
      paddingX="12px"
      minHeight="24px"
      border="1px"
      borderColor="gray.400"
      borderRadius="2px"
      spacing={2}
      zIndex={2}
    >
      <Tooltip hasArrow label={description} placement="top">
        <Box lineHeight={0}>
          <Image
            src={icon.url}
            width={24}
            height={24}
            quality={99}
            alt="Imagem"
          />
        </Box>
      </Tooltip>
      <Text fontSize="10px" color="gray.600">
        {title}
      </Text>
    </HStack>
  );
};
