import Content from "@components/Content";
import Image from "next/image";
import { FC } from "react";
import styles from "./CorrespondentBankNotFound.module.scss";

export interface ICorrespondentBankNotFound {
  description: string;
  subDescription: string;
  icon: any;
}

const CorrespondentBankNotFound: FC<ICorrespondentBankNotFound> = ({
  description,
  subDescription,
  icon,
}) => {
  return (
    <Content className={styles.component}>
      <div>
        <Image
          src={
            icon != null
              ? icon.url
              : "/default-correspondent-bank-not-found.svg"
          }
          width={350}
          height={250}
          alt="Imagem"
        />
        <div className={styles.description}>{description}</div>
        <div className={styles.subDescription}>{subDescription}</div>
      </div>
    </Content>
  );
};
export default CorrespondentBankNotFound;
