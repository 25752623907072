import ButtonStrapi from "@components/ButtonStrapi";
import Content from "@components/Content";
import Markdown from "@components/Markdown";
import { IStrapiButton, Upload } from "interfaces/Home.interface";
import Image from "next/image";
import styles from "./AboutWithIconOverImage.module.scss";

interface IAboutWithIconOverImage {
  image: Upload;
  icon: Upload;
  title: string;
  description: string;
  heading?: string;
  hideImage?: boolean;
  button?: IStrapiButton;
}

const AboutWithIconOverImage: React.FC<IAboutWithIconOverImage> = ({
  image,
  icon,
  title,
  description,
  heading,
  hideImage,
  button,
}) => {
  return (
    <Content>
      <div className={styles.about}>
        {!hideImage && (
          <div className={styles.image}>
            <Image
              src={image.url}
              width={image.width}
              height={image.height}
              quality={90}
              alt="Imagem"
            />
            {icon?.url && (
              <div className={styles.icon}>
                <Image
                  src={icon.url}
                  width={54}
                  height={54}
                  quality={90}
                  alt="Imagem"
                />
              </div>
            )}
          </div>
        )}
        <div className={styles.content}>
          <span className={styles.heading}>{heading}</span>
          <h1 className={styles.title}>{title}</h1>
          <Markdown>{description}</Markdown>

          {button?.label && (
            <ButtonStrapi {...button} buttonProps={{ marginTop: "20px" }} />
          )}
        </div>
      </div>
    </Content>
  );
};

export default AboutWithIconOverImage;
