import Content from '@components/Content'
import Image from 'next/image'
import styles from './CardsWithIcon.module.scss'

const CardsWithIcon = ({ cards }) => {
  const renderCard = ({ icon, text, id }) => {
    return (
      <div key={id} className={styles.card}>
        <div className={styles.iconContainer}>
          <Image src={icon.url} width={35} height={35} alt="Imagem"/>
        </div>
        <p>{text}</p>
      </div>
    )
  }

  return (
    <div className={styles.container}>
      <Content className={styles.content}>{cards.map(renderCard)}</Content>
    </div>
  )
}

export default CardsWithIcon
