import { Divider } from "@chakra-ui/core";
import {
  Button,
  Flex,
  HStack,
  Image,
  Link,
  Stack,
  Text,
  VStack,
} from "@chakra-ui/react";
import { theme } from "@src/config/theme";
import { PlusIcon } from "@src/icons";
import { Advantage, StrapiImageResponsive } from "@src/types/strapi";
import { slugify } from "@src/utils/slugify";
import { useRef, useState } from "react";
import { ProductAdvantage } from "../ProductAdvantage";

const MAX_ADVANTAGES_WHEN_COLLAPSED = 3;

const ProductCardAdvantages = ({ advantages }: { advantages: Advantage[] }) => {
  const [showMoreAdvantages, setShowMoreAdvantages] = useState(false);

  const advantagesToRender = showMoreAdvantages
    ? advantages
    : advantages.slice(0, MAX_ADVANTAGES_WHEN_COLLAPSED);

  const handleSeeMoreClick = () => {
    setShowMoreAdvantages(!showMoreAdvantages);
  };

  return (
    <Stack maxWidth="21.875rem" height="100%">
      <Text fontSize=".75rem" fontWeight="700" color="gray.600">
        Principais vantagens
      </Text>
      <Flex flexWrap="wrap" gridGap={2}>
        {advantagesToRender
          .sort((item) => (item.priority ? 1 : -1))
          .map((advantage, index) => (
            <ProductAdvantage key={index} {...advantage} />
          ))}
        {advantages.length > MAX_ADVANTAGES_WHEN_COLLAPSED &&
          !showMoreAdvantages && (
            <HStack
              onClick={handleSeeMoreClick}
              cursor="pointer"
              height="24px"
              paddingX="8px"
              border="1px"
              borderColor="primary.500"
              borderRadius="2px"
              color="primary.500"
              spacing={1}
              zIndex={2}
            >
              <Text fontSize="0.625rem">Ver mais</Text>
              <PlusIcon />
            </HStack>
          )}
      </Flex>
    </Stack>
  );
};

interface ProductCardProps {
  id: string;
  name: string;
  description?: string;
  image?: StrapiImageResponsive;
  advantages?: Advantage[];
}

export const ProductCard = ({
  id,
  name,
  description,
  image,
  advantages,
}: ProductCardProps) => {
  const seeDetailsButton = useRef<HTMLButtonElement | null>(null);

  return (
    <VStack
      height="100%"
      width={{ base: "full", md: "24.875rem" }}
      borderRadius=".625rem"
      borderWidth="1px"
      borderColor="gray.200"
      position="relative"
    >
      <Link
        href={`/produtos/${slugify(name)}-${id}`}
        position="absolute"
        height="100%"
        width="100%"
        zIndex={1}
        _hover={{ cursor: "pointer" }}
        onMouseOver={() => {
          seeDetailsButton.current?.style.setProperty(
            "background",
            theme.colors.primary[600]
          );
        }}
        onMouseLeave={() => {
          seeDetailsButton.current?.style.setProperty(
            "background",
            theme.colors.primary[500]
          );
        }}
      />
      {image && (
        <VStack
          width="full"
          marginTop="0 !important"
          borderRadius=".625rem .625rem 0 0"
        >
          <Image
            src={image.desktop_image.url}
            width={{ base: "full", lg: 398 }}
            height={{ base: 230, lg: 244 }}
            quality={99}
            borderRadius=".625rem .625rem 0 0"
            alt="Imagem"
          />
        </VStack>
      )}
      <VStack
        height="100%"
        width="full"
        textAlign="start"
        justifyContent="space-between"
        flex={1}
      >
        <Stack
          width={"full"}
          gridGap={"18px"}
          paddingX={{ base: "1rem", md: "1.5rem" }}
          paddingY={{ base: "0.8rem", md: "1.125rem" }}
          flex={1}
          justifyContent={"space-between"}
          id="aqui"
        >
          <Stack>
            <Text
              textTransform={"capitalize"}
              fontSize={{ base: "1.2rem", md: "1.5rem" }}
              color="gray.600"
              fontWeight="600"
            >
              {name}
            </Text>
            {description && (
              <Text
                fontSize={{ base: ".75rem", md: ".9375rem" }}
                color="gray.600"
              >
                {description}
              </Text>
            )}
          </Stack>
          {(advantages?.length && (
            <Flex minHeight={{ base: "auto", lg: "5.375rem" }}>
              <ProductCardAdvantages advantages={advantages} />
            </Flex>
          )) ||
            null}
        </Stack>
        <VStack
          width="full"
          marginTop="0px !important"
          paddingBottom={{ base: "0.8rem", md: "1.125rem" }}
          paddingTop={0}
        >
          <Divider width={"full"} />
          <Flex paddingX={{ base: "1rem", md: "1.5rem" }} width={"full"}>
            <Button
              colorScheme="primary"
              marginTop="10px !important"
              width="100%"
              height={{ base: "2.1875rem", md: "2.8125rem" }}
              ref={seeDetailsButton}
            >
              Ver detalhes
            </Button>
          </Flex>
        </VStack>
      </VStack>
    </VStack>
  );
};
