import { Button } from "@chakra-ui/core";
import Link from "next/link";
import React, { FC } from "react";
export interface ButtonProps {
  type: string;
  url?: string;
  page?: any;
  label?: string;
  backgroundColor?: string;
  color?: string;
  variantColor?: string;
  colorScheme?: string;
}
const getExternalLinkProps = (external) =>
  external ? { target: "_blank", rel: "noopener noreferrer" } : {};

const ButtonStrapi: FC<ButtonProps> = ({
  children,
  type,
  url,
  page,
  label,
  ...props
}) => {
  return (
    <Link href={type === "external" && url ? url : `/${page?.key}`} passHref>
      <Button
        as="a"
        {...getExternalLinkProps(type === "external")}
        backgroundColor="#3D9DEF"
        color="#FFF"
        variantColor="blue"
        height="57px"
        {...props}
        isTruncated
      >
        {children || <p>{label}</p>}
      </Button>
    </Link>
  );
};

export default ButtonStrapi;
