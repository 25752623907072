import { Box, Flex } from "@chakra-ui/react";
import Markdown from "@components/Markdown";
import { StrapiCertification } from "@src/types/strapi";
import Image from "next/image";
import { memo } from "react";

const Certification = ({ description, flip, image }: StrapiCertification) => {
  return (
    <Flex
      alignItems="center"
      background="gray.50"
      color="gray.700"
      flexDirection={{
        base: "column-reverse",
        lg: flip ? "row" : "row-reverse",
      }}
      fontWeight={500}
      gridGap={{ base: "1.5rem", md: "3.625rem" }}
      justifyContent="space-between"
      minHeight="11.8125rem"
      paddingX={{ base: "1.5rem", md: "3rem" }}
      paddingY={{ base: "1.75rem", md: "2.8125rem" }}
      width="full"
    >
      <Markdown>{description}</Markdown>
      <Box width={image.width} height={image.height}>
        <Image
          src={image.url}
          layout={"fixed"}
          width={Number(image.width)}
          height={Number(image.height)}
          alt={image.name}
        />
      </Box>
    </Flex>
  );
};

export default memo(Certification);
